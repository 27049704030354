
/*? ============= Mobile CSS ============= */
@media screen and (max-width: 600px) {

     .aboutMeSubBoxes {
          min-width: 420px;
          max-width: 420px;
          background-image: url("../../src/img/headerBackground/Header_Colorful_Stretched.png");
          background-repeat: no-repeat;
          background-size: cover;
          /* background-size: auto; */
          /* background-size: 900px; */
          border-color: white;
          border-style: solid;
          border-width: 10px;
          border-radius: 60px;
          -webkit-border-radius: 60px;
          -moz-border-radius: 60px;
          -ms-border-radius: 60px;
          -o-border-radius: 60px;
          font-family: Inter;
          /* font-family: "SF-Mono"; */
          font-size: 14px;
          justify-content: center;
     }

     .aboutMeTitle {
          font-family: "SF-Mono";
          font-size: 40px;
          border-style: solid;
          border-width: 10px;
          padding: 20px;
          margin-top: 30px;
          margin-bottom: 30px;
     }

     .aboutTextPadding {
          padding: 20px 30px 20px 20px;
          font-size: 14px;
     }

     .aboutRightBox {
          /* margin-right: 0px; */
          justify-content: center;
     }

     .aboutCenterBox {
          /* margin-right: 0px; */
          justify-content: center;
     }

     .aboutText {
          margin-top: 10px;
          padding: 10px 0px 25px 0px;
          justify-content: center;
     }

     .aboutLeftBox {
          margin-left: 10%;
          margin-right: 10%;
          justify-content: center;
     }

     .HeaderAboutSpacer {
          margin-top: 20px;
          padding-top: 50px;
          margin-bottom: 25px;
     }

     .aboutMePar1Picture {
          width: 300px;
          border-radius: 25px;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
     }

     .aboutMePar2Picture {
          width: 400px;
          border-radius: 25px;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
     }

     .aboutMePar3Picture {
          width: 450px;
          border-radius: 25px;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
     }

     .aboutMePar4Picture {
          width: 450px;
          border-radius: 25px;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
     }

     .aboutMePar5Picture {
          height: 175px;
          border-radius: 25px;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
     }

     .aboutMePar6Picture {
          height: 200px;
          border-radius: 25px;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
     }

     .portVerisonText {
          font-family: "SF-Mono";
          font-size: 15px;
          /* margin-left: 42%; */
          margin-top: 10px;
          /* text-align: start; */
     }

     .portVersion{
          font-family: "SF-Mono";
          font-size: 15px;
          /* margin-left: 42%; */
          margin-top: 20px;
     }

     .portVersionBox {
          align-self: center;
          text-align: center;
          align-items: center;
          align-content: start;
     }
}