



@media screen and (min-width: 600px) {

  .codyMap {
    /* max-width: 850px; */
    max-height: 600px;
    border-radius: 80px;
    -webkit-border-radius: 80px;
    -moz-border-radius: 80px;
    -ms-border-radius: 80px;
    -o-border-radius: 80px;
  }

  .codyDescriptionText{
    font-size: 25px;
  }

  .aboutAreaBox {
      width: 600px;
      background-image: url("../../src/img/headerBackground/Header_Colorful_Stretched.png");
      background-repeat: no-repeat;
      background-size: cover;
      border-color: white;
      border-style: solid;
      border-width: 10px;
      border-radius: 60px;
      -webkit-border-radius: 60px;
      -moz-border-radius: 60px;
      -ms-border-radius: 60px;
      -o-border-radius: 60px;
      font-family: Inter;
      /* font-family: "SF-Mono"; */
      font-size: 20px;
      padding: 5px 10px 30px 10px;
  }

  .googleEarthDownloadBox {
      font-family: "SF-Mono";
      font-size: 50px;
      border-style: solid;
      border-width: 10px;
      text-decoration: none;
  }

  .googleEarthDownloadLink {
      text-decoration: none;
  }

  .googleEarthDownloadBox:hover{
    /* font-size: 40px; */
    color: black;
    background-color: white;
  }
}