



@media screen and (min-width: 600px) {

     .footerIcon {
          width: 50px;
     }

     .devLinksBox {
          /* width: 100%; */
          margin-top: 70px;
          border-style: solid;
          border-width: 10px;
          background-image: url("../../img/headerBackground/Header_Colorful.jpg");
     }

     .footerIconBox {
          padding: 20px 20px 15px 20px;
     }

     .devLinksContainer {
          width: 80%;
          padding-bottom: 50px;
     }
}

@media screen and (max-width: 600px) {

     .footerIcon {
          width: 20px;
     }

     .devLinksBox {
     /* width: 100%; */
     margin-top: 20px;
     border-style: solid;
     border-width: 5px;
     background-image: url("../../img/headerBackground/Header_Colorful_Wide_Mobile.png");
     
     }

     .footerIconBox {
          padding: 10px 10px 5px 10px;
     }

     .devLinksContainer {
          width: 80%;
          padding: 50px;
     }

}