
/*! ============= Desktop CSS ============= */
@media screen and (min-width: 600px) {

     .aboutMeSubBoxes {
          /* width: 1100px; */
          /* max-width: 900px; */
          background-image: url("../../src/img/headerBackground/Header_Colorful_Stretched.png");
          background-repeat: no-repeat;
          background-size: cover;
          /* background-size: auto; */
          /* background-size: 900px; */
          border-color: white;
          border-style: solid;
          border-width: 10px;
          border-radius: 60px;
          -webkit-border-radius: 60px;
          -moz-border-radius: 60px;
          -ms-border-radius: 60px;
          -o-border-radius: 60px;
          font-family: Inter;
          /* font-family: "SF-Mono"; */
          font-size: 20px;
          padding: 5px 10px 30px 10px;
     }

     .aboutMeTitle {
          font-family: "SF-Mono";
          font-size: 50px;
          border-style: solid;
          border-width: 10px;
          padding: 30px;
          margin-top: 30px;
          margin-bottom: 30px;
     }

     .aboutText {
          padding: 30px 10px 0px 20px;
     }

     .aboutRightBox {
          margin-right: 10%;
          justify-content: end;
     }

     .aboutLeftBox {
          margin-left: 10%;
          margin-right: 10%;
          justify-content: start;
     }

     .HeaderAboutSpacer {
          margin-top: 125px;
          padding-top: 50px;
          margin-bottom: 100px;
     }

     .aboutMePar1Picture {
          height: 350px;
          margin-left: 22%;
          border-radius: 25px;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
     }

     .aboutMePar2Picture {
          height: 350px;
          /* margin-top: 25px; */
          margin-left: 25%;
          border-radius: 25px;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
     }

     .aboutMePar3Picture {
          height: 350px;
          margin-left: 15%;
          border-radius: 25px;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
     }

     .aboutMePar4Picture {
          height: 450px;
          margin-left: 30%;
          border-radius: 25px;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
     }

     .aboutMePar5Picture {
          height: 250px;
          margin-left: 10%;
          margin-bottom: 50px;
          border-radius: 25px;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
     }

     .aboutMePar6Picture {
          height: 250px;
          margin-left: 35%;
          border-radius: 25px;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
          -ms-border-radius: 25px;
          -o-border-radius: 25px;
     }

     .aboutTextPadding {
          padding: 35px 20px 0px 20px;
     }

     .portVerisonText {
          font-family: "SF-Mono";
          font-size: 25px;
          /* margin-left: 42%; */
          margin-top: 10px;
          /* text-align: start; */
     }

     .portVersion{
          font-family: "SF-Mono";
          font-size: 25px;
          /* margin-left: 42%; */
          margin-top: 40px;
     }

     .portVersionBox {
          align-self: center;
          text-align: center;
          /* justify-content: center; */
          align-items: center;
          align-content: start;
     }

}